import React from "react";
import { useHistory } from "react-router-dom";
import "./animation.css";
import "./animation2.css";
import "./animation3.css";

export const RestaurantHomePage = () => {
    const history = useHistory();

    const handleRestaurantSignInClick = () => {
      history.push("/Restaurant-sign-in");
    };
  
    const handleSignInClick = () => {
      history.push("/sign-in");
    };
  return (
    <div>
    <meta charSet="utf-8" />
 
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <meta content name="keywords" />
    <meta content name="description" />
    {/* Google Web Fonts */}
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
    <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&family=Nunito:wght@600;700;800&family=Pacifico&display=swap" rel="stylesheet" />
    {/* Icon Font Stylesheet */}
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
    <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
    {/* Libraries Stylesheet */}

    <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
    <link href="lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
    {/* Customized Bootstrap Stylesheet */}
    <link href="css/bootstrap.min.css" rel="stylesheet" />
    {/* Template Stylesheet */}
    <link href="css/style.css" rel="stylesheet" />
    <div className="container-xxl bg-white p-0">
      <div id="spinner" className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <div className="container-xxl position-relative p-0">          
        <div className="container-xxl py-5 bg-dark hero-header mb-5">
          <div className="container my-5 py-5">
            <div className="row align-items-center g-5">
              <div className="col-lg-6 text-center text-lg-start">
                <h1 className="display-3 text-white animated slideInLeft">Embark on a <br />Gastronomic Journey</h1>
                <p className="text-white animated slideInLeft mb-4 pb-2">Discover an array of culinary wonders from multiple restaurants. From cozy cafes to elegant eateries, our platform offers a delightful tapestry of flavors to satisfy every palate. Bon appétit!</p>
                <button className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft" onClick={handleRestaurantSignInClick}>
                  ADD Your Restaurant
                </button>
                <button className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft" onClick={handleSignInClick}>
                  Book A Table
                </button></div>
              <div   className="col-lg-6 text-center text-lg-end overflow-hidden">
                <img className="img-fluid" src="https://themewagon.github.io/restoran/img/hero.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

