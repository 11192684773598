import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const cancelReservationRestaurant = async (reservationId, restaurantId) => {
    console.log('User ID:', restaurantId);
    console.log('Reservation ID:', reservationId);
    const db = firebase.firestore();

    const reservationRef = db.collection('reservations').doc(reservationId);
    const reservationDoc = await reservationRef.get();

    if (!reservationDoc.exists) {
        return { status: 'error', code: 404, message: 'Reservation not found' };
    }

    const reservationData = reservationDoc.data();

    if (reservationData.restaurantId !== restaurantId) {
        return { status: 'error', code: 403, message: 'Access denied' };
    }
    await reservationRef.delete();

    const availabilityRef = db.collection('dateAvailabilities')
        .where('restaurantId', '==', reservationData.restaurantId)
        .where('date', '==', reservationData.date)
        .limit(1);
    
    const availabilitySnapshot = await availabilityRef.get();
    
    if (!availabilitySnapshot.empty) {
        const availabilityDoc = availabilitySnapshot.docs[0];
        const availableTimes = availabilityDoc.data().availableTimes;
        const updatedTimes = availableTimes.concat(reservationData.time);

        await availabilityDoc.ref.update({ availableTimes: updatedTimes });
    }

    return { status: 'success', code: 200, message: 'Reservation canceled successfully' };
};
