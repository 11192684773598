import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const makeReservation = async (availabilityId, requestedTime, numberOfPeople) => {
    const userId = firebase.auth().currentUser?.uid;
    if (!userId) {
        return { status: 'error', code: 401, message: 'Not signed in!' };
    }

    const db = firebase.firestore();
    const requestedDateRef = db.collection('dateAvailabilities').doc(availabilityId);
    const requestedDateDoc = await requestedDateRef.get();
    const availabilityInfo = requestedDateDoc.data();
    const { availableTimes } = availabilityInfo;

    if (!availableTimes.includes(requestedTime)) {
        return { status: 'error', code: 400, message: 'Time is no longer available' };
    }

    await db.collection('reservations').add({
        userId,
        createdAt: Date.now(),
        restaurantId: availabilityInfo.restaurantId,
        date: availabilityInfo.date,
        time: requestedTime,
        numberOfPeople,
    });
    
    await requestedDateRef.update({
        availableTimes: availableTimes.filter(time => time !== requestedTime),
    });

    return { status: 'success', code: 200, message: 'Reservation made successfully' };
};
