import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Button,
  CenteredContentBox,
  HeadingSmall,
  TextInput,
  TextArea,
  UploadSingleFileButton,
} from "../ui";
import { uploadFileRestaurant } from "../util";
import { getCurrentUserInfo } from "./getCurrentUserInfo";
import { updateCurrentRestaurantInfo } from "./updateCurrentRestaurantInfo";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Form = styled.div`
  width: 600px;
  margin: 32px;
`;

const FieldsTable = styled.table`
  td {
    padding: 8px;
    width: 50%;
  }
`;

const FullWidthInput = styled(TextInput)`
  width: 100%;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

/*
    This page loads a user's current profile data (name, bio, etc.)
    and allows them to edit it. When the user clicks "save", the changes
    will be persisted to Firebase.
*/
export const EditRestaurantPage = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [name, setname] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [street, setstreet] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [emailAddress, setemailAddress] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [bio, setBio] = useState("");
  const history = useHistory();

useEffect(() => {
    const loadUserInfo = async () => {
      try {
        // Get the reference to the current user's document in the "users" collection
        const userId = firebase.auth().currentUser.uid;
        const userRef = firebase.firestore().collection('restaurants').doc(userId);

        // Fetch the user info from Firestore
        const snapshot = await userRef.get();
        if (snapshot.exists) {
          const userInfo = snapshot.data();
         

          // Update the component state with the retrieved user info
          setname(userInfo.name || '');
          setemailAddress(userInfo.emailAddress || '');
          setcity(userInfo.address.city || '');
          setstate(userInfo.address.state || '');
          setstreet(userInfo.address.street || '');
          setzipCode(userInfo.address.zipCode || '');
          setBio(userInfo.bio || '');
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsLoading(false);
      }
    };

    loadUserInfo();
  }, []);

  const handleFileSelect = (file) => {
    setimageUrl(file);
  };

  const onSubmitChanges = async () => {
    const userId = firebase.auth().currentUser.uid;
    const profilePictureUrl = imageUrl
      ? await uploadFileRestaurant(imageUrl, "profilePictures",userId)
      : null;
    const changes = {
      address:{city,state, 
        street,
        zipCode},
      name,
      emailAddress,
      bio,
    };
    await updateCurrentRestaurantInfo(
      profilePictureUrl ? { ...changes, profilePictureUrl } : changes
    );

    history.push("/");
  };

  return (
    <CenteredContentBox>
      <Form>
        <HeadingSmall>Edit Profile</HeadingSmall>
        <FieldsTable>
          <tbody>
            <tr>
              <td>Restaurant Name:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>City:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>State:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={state}
                  onChange={(e) => setstate(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Street:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={street}
                  onChange={(e) => setstreet(e.target.value)}
                />
              </td>
            </tr>
            
            <tr>
              <td>ZipCode:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={zipCode}
                  onChange={(e) => setzipCode(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>
                <FullWidthInput
                  disabled={isLoading}
                  value={emailAddress}
                  onChange={(e) => setemailAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Upload a Profile Picture:</td>
              <td>
                <UploadSingleFileButton
                  disabled={isLoading}
                  onFileUploaded={handleFileSelect}
                />
              </td>
            </tr>
            <tr>
              <td>Bio:</td>
              <td>
                <TextArea
                  disabled={isLoading}
                  rows="5"
                  value={bio}
                  style={{ width: "100%" }}
                  onChange={(e) => setBio(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </FieldsTable>
        <FullWidthButton disabled={isLoading} onClick={onSubmitChanges}>
          Save Changes
        </FullWidthButton>
      </Form>
    </CenteredContentBox>
  );
};
