import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const signIn = async (email, password) => {
  try {
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);

    // Check if the user exists in the "users" collection
    const userRef = firebase.firestore().collection("users").doc(result.user.uid);
    const userSnapshot = await userRef.get();
    if (!userSnapshot.exists) {
      throw new Error("You are not registered as a client. ");
    }

    // If the user exists, return some additional user data
    const userData = userSnapshot.data();
    return { userData }; // You can include other user-related data as needed

  } catch (e) {
    throw new Error("Error When sign In");
  }
};
