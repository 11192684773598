import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const makeAvailableTimes = async (selectedTimes, formatedDate) => {
  const userId = firebase.auth().currentUser?.uid;
  if (!userId) {
    return { status: 'error', code: 401, message: 'Not signed in!' };
  }

  const db = firebase.firestore();

  // Check if a document with the same date and userId exists in the collection
  const querySnapshot = await db
    .collection('dateAvailabilities')
    .where('date', '==', formatedDate)
    .where('restaurantId', '==', userId)
    .get();

  if (querySnapshot.empty) {
    // If no document exists, create a new one
    await db.collection('dateAvailabilities').add({
      
    restaurantId: userId,
      date: formatedDate,
      availableTimes: selectedTimes,
    });
  } else {
    // If a document exists, update the existing document
    const docId = querySnapshot.docs[0].id;
    await db.collection('dateAvailabilities').doc(docId).update({
        availableTimes: selectedTimes,
    });
  }

  return { status: 'success', code: 200, message: 'dateAvailabilities made successfully' };
};
