import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App, serviceWorker } from "./app";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/app-check";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBvKmgMZINGq-LD_oXXxKwysNWgrWQ9IAk",
  authDomain: "reservation-cee41.firebaseapp.com",
  databaseURL: "https://reservation-cee41-default-rtdb.firebaseio.com",
  projectId: "reservation-cee41",
  storageBucket: "reservation-cee41.appspot.com",
  messagingSenderId: "964375079977",
  appId: "1:964375079977:web:48e852fc29d9b083c00038",
  measurementId: "G-J1DZF5N799"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();





// This is where the magic happens. React renders our App component
// inside the div with the id "root"
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
