import React, { useState,useEffect } from 'react';
import { ReservationsListItem } from './ReservationsListItem';
import { SignOutButton } from '../auth';
import {
    Button,
    Divider,
    Heading,
    HeadingSmall,
    HoveringButton,
    Link,
    MaxWidthContentSection,
    Modal,
    SelectionList,
} from '../ui';
import { ReservationDetailFormRestaurant } from './ReservationDetailFormRestaurant';
import {subscribeToCurrentRestaurantReservations} from './subscribeToCurrentRestaurantReservations';
import { ReservationsListItemRestaurant } from './ReservationsListItemRestaurant';
import { MakeAReservationAvailableTimesForm } from './MakeAReservationAvailableTimesForm';

/*
    This page displays all of the user's current reservations in a list
*/
export const ReservationsListRestaurantPage = () => {
    const [reservations, setReservations] = useState([]);
    const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
    const [restaurant, setRestaurant] = useState({});
    const [selectedReservation, setSelectedReservation] = useState(undefined);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const onItemSelected = reservation => {
        setSelectedReservation(reservation);
        setDetailModalIsOpen(true);
    }

    const onRequestCloseModal = () => {
        setSelectedReservation(undefined);
        setDetailModalIsOpen(false);
    }

    useEffect( ()=> {
        const unsubscribe = subscribeToCurrentRestaurantReservations(results =>{
            setReservations(results);
        })
        return unsubscribe;
    }, []);

    return (
        <>
        <MaxWidthContentSection>
            <div style={{ height: '64px' }}>
                <SignOutButton style={{ float: 'right' }} />
                <Link to='/edit-restaurant-profile'>
                    <Button
                        style={{ float: 'right', marginRight: '8px' }}
                    >Profile</Button>
                </Link>
            </div>
            <Heading>MY RESERVATIONS</Heading>
            <Divider />
            {reservations.length > 0
                ? <SelectionList
                    items={reservations}
                    keyProperty='id'
                    onItemSelected={onItemSelected}
                    itemComponent={ReservationsListItemRestaurant} />
                : <HeadingSmall
                    style={{ textAlign: 'center' }}
                    >You have no reservations yet!</HeadingSmall>}
        </MaxWidthContentSection>
       
            <HoveringButton onClick={() => setModalIsOpen(true)}>+ Set Availabilities Date</HoveringButton>
            <Modal
            isOpen={detailModalIsOpen}
            onRequestClose={onRequestCloseModal}>
                <ReservationDetailFormRestaurant
                    reservation={selectedReservation}
                    onClose={onRequestCloseModal} />
            </Modal>    
        <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}>
                    <MakeAReservationAvailableTimesForm restaurant={restaurant} onClose={() => setModalIsOpen(false)} />
            </Modal>
        </>
    );
}