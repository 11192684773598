import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Button,
    Heading,
    Thumbnail,
} from '../ui';
import { cancelReservationRestaurant } from './cancelReservationRestaurant';
import {ReportCustomer} from './ReportCustomer'
import {RewardCustomer} from './RewardCustomer'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
const Form = styled.div`
    width: 350px;
    margin: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const InfoSection = styled.table`
    text-align: left;
    padding: 16px;
`;

const InfoSectionCell = styled.td`
    padding: 8px;
`;

const LeftCell = styled(InfoSectionCell)`
    font-weight: bold;
    width: 30%;
`;

const RightCell = styled(InfoSectionCell)`
`;

const FullWidthButton = styled(Button)`
    display: block;
    width: 100%;
`;

const withTopMargin = px => ({ marginTop: `${px}px` });

/*
    This component is displayed inside a modal when the user clicks
    "view" on a given item on the reservations list page. It shows
    basic details about the reservation, and also allows users
    to cancel their reservation.
*/
export const ReservationDetailFormRestaurant = ({ reservation, onClose = () => {} }) => {
    const { restaurant, date, time ,numberOfPeople} = reservation || {};
    const { name, address = {}, imageUrl } = restaurant || {};
    const [userData, setUserData] = useState(null);
    
  
    
    useEffect(() => {
        // Fetch the user data from Firestore based on the userId
        const fetchUserData = async () => {
            try {
                const userRef = firebase.firestore().collection('users').doc(reservation.userId);
                const userDoc = await userRef.get();

                if (userDoc.exists) {
                    setUserData(userDoc.data());
                } else {
                    console.log("User not found.");
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const userId = firebase.auth().currentUser.uid;
    
    const onClickCancel = async () => {
        const result = await cancelReservationRestaurant(reservation.id, userId);
        if (result.status === 'success') {
          alert(result.message);
          onClose();
        } else {
          alert('Failed to cancel reservation');
        }
      };
      
      const onClickReport = async () => {
        const result = await ReportCustomer(reservation.id, userId);
        if (result.status === 'success') {
          alert(result.message);
          onClose();
        } else {
          alert('Failed to Reporte the Customer');
        }
      };
     
      const onClickReward = async () => {
        const result = await RewardCustomer(reservation.id, userId);
        if (result.status === 'success') {
          alert(result.message);
          onClose();
        } else {
          alert('Failed to cancel reservation');
        }
      };
      
    return (
        <Form>
            <Heading>Reservation for<br />{userData && userData.firstName} {userData && userData.lastName}</Heading>
            <Thumbnail height="250px" width="250px" url={userData && userData.imageUrl} />
            <br></br>
            <InfoSection>
                <tbody>
                    <tr>
                        <LeftCell>Date:</LeftCell>
                        <RightCell>{date}</RightCell>
                    </tr>
                    <tr>
                        <LeftCell>Time:</LeftCell>
                        <RightCell>{time}</RightCell>
                    </tr>
                    <tr>
                        <LeftCell>Email:</LeftCell>
                        <RightCell>
                        {userData && userData.emailAddress}
                         
                        </RightCell>
                    </tr>
                    <tr>
                        <LeftCell>Place:</LeftCell>
                        <RightCell>
                        {numberOfPeople}
                         
                        </RightCell>
                    </tr>

                    
                </tbody>
            </InfoSection>
            <FullWidthButton onClick={onClickCancel} style={{ marginTop: 8, backgroundColor: '#ffd600'}}>
            Cancel Reservation
            </FullWidthButton>
            <FullWidthButton onClick={onClickReport} style={{ marginTop: 8, backgroundColor: '#ed0d25'}}>
             Report customer
            </FullWidthButton>
            <FullWidthButton onClick={onClickReward} style={withTopMargin(8)}>
             Reward customer
            </FullWidthButton>
        </Form>
    );
}