import React from 'react';
import { SignInFormRestaurant } from './SignInFormRestaurant';
import { CenteredContentBox } from '../ui';

/*
    This component basically just renders the Sign-In Form
    inside of a nice-looking content box.
*/
export const SignInPageRestaurant = () => {
    return (
        <>
        <CenteredContentBox>
            <SignInFormRestaurant />
        </CenteredContentBox>
        </>
    );
};