import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const submitReview = async (restaurantId, newReview) => {
    const userId = firebase.auth().currentUser?.uid;
    if (!userId) {
        return { status: 'error', code: 401, message: 'Not signed in!' };
    }

    const db = firebase.firestore();
    await db.collection('reviews').add({
        userId,
        restaurantId,
        rating: newReview.rating,
        text: newReview.text,
        imageUrls: newReview.imageUrls,
    });

    const restaurantRef = db.collection('restaurants').doc(restaurantId);
    const restaurantDoc = await restaurantRef.get();
    const restaurantData = restaurantDoc.data();
    const oldNumberOfRatings = restaurantData.numberOfRatings || 0;
    const oldRating = restaurantData.rating || 0;

    const newNumberOfRatings = oldNumberOfRatings + 1;
    const newRating =
        (oldRating * oldNumberOfRatings + newReview.rating) / newNumberOfRatings;

    await restaurantRef.update({
        numberOfRatings: newNumberOfRatings,
        rating: newRating,
    });

    return { status: 'success', code: 200, message: 'Review submitted successfully' };
};
