import firebase from 'firebase/compat/app';
import { getCurrentUser } from '../auth';

export const updateCurrentRestaurantInfo = async updates => {
    const currentUser = getCurrentUser();

    if (!currentUser) return;

    await firebase.firestore()
        .collection('restaurants')
        .doc(currentUser.id)
        .update(updates);
}