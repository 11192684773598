import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const getUserInfo = async (userId) => {
    const userRef = firebase.firestore().collection('users').doc(userId);
    const userDoc = await userRef.get();

    if (!userDoc.exists) {
        return null;
    }

    const userInfo = {
        id: userDoc.id,
        ...userDoc.data(),
    };

    return userInfo;
};
