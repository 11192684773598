import { v4 as uuid } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore'; // Import Firestore


const prefix = 'gs://reservation-cee41.appspot.com';

export const uploadFileuser = async (file, folderName,user) => {
    const fileExtension = file.type === 'image/png'
        ? '.png'
        : file.type === 'image/jpeg'
            ? '.jpg'
            : '';

    const filePath = folderName + '/' + uuid() + fileExtension;
    const storageRef = firebase.storage().ref(filePath);

    try {
        // Upload the file to Google Cloud Storage
        await storageRef.put(file);

        // Get the publicly accessible URL of the uploaded file
        const downloadURL = await storageRef.getDownloadURL();

        // Save the URL in the database (Firestore)
        const firestore = firebase.firestore();
        const imageDocRef = firestore.collection('users').doc(user); // Create a new document with a random ID
        await imageDocRef.update({ imageUrl: downloadURL });

        // Return the URL with the prefix
        return prefix + filePath;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};
