import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const RewardCustomer= async (reservationId, restaurantId) => {
    const db = firebase.firestore();

    const reservationRef = db.collection('reservations').doc(reservationId);
    const reservationDoc = await reservationRef.get();
    

    if (!reservationDoc.exists) {
        return { status: 'error', code: 404, message: 'Reservation not found' };
    }

    const reservationData = reservationDoc.data();
  

    if (reservationData.restaurantId !== restaurantId) {
        return { status: 'error', code: 403, message: 'Access denied' };
    }

    // Save the reservation data to 'reservationshistorique' collection
    try {
        const historiqueRef = db.collection('reservationshistorique');
        const reportedReservationData = { ...reservationData, status: 'Rewarded' };
        await historiqueRef.add(reportedReservationData);
    } catch (error) {
        console.error('Error saving data to reservationshistorique:', error);
    }

    await reservationRef.delete();


    // Update the user's yumpoints by -10
    try {
        const userRef = db.collection('users').doc(reservationData.userId);
        const userDoc = await userRef.get();

        if (userDoc.exists) {
            const userData = userDoc.data();
            const currentYumpoints = userData.yumpoints || 0;
            const newYumpoints = currentYumpoints + 10;
            
            await userRef.update({ yumpoints: newYumpoints });
        }
    } catch (error) {
        console.error('Error updating user yumpoints:', error);
    }

    return { status: 'success', code: 200, message: 'Reservation canceled successfully' };
};

