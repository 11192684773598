import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { validate as isEmail } from "isemail";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

import {
  Button,
  CenteredContentBox,
  ErrorMessage,
  HeadingSmall,
  TextInput,
  TextArea,
} from "../ui";
import { signOut } from "./signOut";

const Form = styled.div`
  width: 600px;
  margin: 32px;
`;

const FieldsTable = styled.table`
  width: 100%;
  td {
    padding: 8px;
    width: 50%;
  }
`;

const FullWidthInput = styled(TextInput)`
  width: 100%;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

export const CreateAccountPageRestaurant = () => {
  const imageUrl='https://firebasestorage.googleapis.com/v0/b/reservation-cee41.appspot.com/o/profilePictures%2Frestaurant.png?alt=media&token=94179140-7270-4b36-be29-b3e6e0a7dbf4';
  const [name, setname] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [street, setstreet] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bio, setBio] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const validateForm = () => {
    if (!name || !emailAddress)
      return "Please fill out all fields";
    if (!isEmail(emailAddress)) return "Please enter a valid email address";
    if (password !== confirmPassword) return "Passwords do not match";
    return null;
  };

  const onClickCreate = async () => {
    setErrorMessage("");

    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    try {
      const auth = getAuth();
      const { user } = await createUserWithEmailAndPassword(
        auth,
        emailAddress,
        password
      );

      const db = getFirestore();
      const userRef = doc(collection(db, "restaurants"), user.uid);
      await setDoc(userRef, {
        address:{city,state, 
          street,
          zipCode},
          imageUrl,
        name,
        emailAddress,
        bio,
      });

      alert("Account Created! Check your email for account confirmation");

      await signOut();
      history.push("/Restaurant-sign-in");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <CenteredContentBox>
      <Form>
        <HeadingSmall>Create Your Restaurant</HeadingSmall>
        {errorMessage ? (
          <ErrorMessage
            style={{
              marginBottom: "16px",
            }}
          >
            {errorMessage}
          </ErrorMessage>
        ) : null}
        <FieldsTable>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>
                <FullWidthInput
                  value={name}
                  placeholder="Restaurant"
                  onChange={(e) => setname(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>City:</td>
              <td>
                <FullWidthInput
                  value={city}
                  placeholder="Casablanca"
                  onChange={(e) => setcity(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>State:</td>
              <td>
                <FullWidthInput
                  value={state}
                  placeholder="Anfa"
                  onChange={(e) => setstate(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Street:</td>
              <td>
                <FullWidthInput
                  value={street}
                  placeholder="10 Rue anatole france"
                  onChange={(e) => setstreet(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Zip Code:</td>
              <td>
                <FullWidthInput
                  value={zipCode}
                  placeholder="20400"
                  onChange={(e) => setzipCode(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Email Address:</td>
              <td>
                <FullWidthInput
                  value={emailAddress}
                  placeholder="john.doe@gmail.com"
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Password:</td>
              <td>
                <FullWidthInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Confirm Password:</td>
              <td>
                <FullWidthInput
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Bio:</td>
              <td>
                <TextArea
                  rows="5"
                  value={bio}
                  placeholder="Tell others a little bit about your Restaurant"
                  style={{ width: "100%" }}
                  onChange={(e) => setBio(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </FieldsTable>
        <FullWidthButton onClick={onClickCreate}>
          Create Account
        </FullWidthButton>
      </Form>
    </CenteredContentBox>
  );
};
