import firebase from 'firebase/compat/app';
import { getCurrentUser } from '../auth';

export const updateCurrentUserInfo = async (updates) => {
  try {
    const currentUser = await getCurrentUser();

    if (!currentUser) {
      console.log('No current user found.');
      return;
    }

    await firebase
      .firestore()
      .collection('users')
      .doc(currentUser.id)
      .update(updates);

    console.log('User information updated successfully.');
  } catch (error) {
    console.error('Error updating user information:', error);
  }
};
