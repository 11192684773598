import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const searchRestaurants = async searchString => {
    const db = firebase.firestore();

    const querySnapshot = await db.collection('restaurants')
        .where('name', '>=', searchString)
        .where('name', '<=', searchString + '\uf8ff')
        .limit(3)
        .get();

    const restaurants = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));

    return restaurants;
};
