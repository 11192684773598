import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, HeadingSmall } from '../ui';
import { makeAvailableTimes } from './makeAvailableTimes';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const Content = styled.div`
  min-height: 375px;
  min-width: 625px;
`;

const TimeTable = styled.table`
  margin-top: 32px;
  text-align: center;
  width: 100%;
`;

const TimeOption = styled.td`
  border: 2px solid ${({ selected }) => (selected ? '#a4d22a;' : 'transparent')};
  border-radius: 8px;
  color: ${({ isAvailable }) => (isAvailable ? '#a4d22a' : '#888')};
  cursor: pointer;
  font-weight: bold;
  padding: 16px;
`;

const FullWidthButton = styled(Button)`
  margin-top: 32px;
  width: 100%;
`;

const possibleTimes = [
  ['5:00PM', '5:30PM', '6:00PM', '6:30PM'],
  ['7:00PM', '7:30PM', '8:00PM', '8:30PM'],
  ['9:00PM', '9:30PM', '10:00PM', '10:30PM'],
];

export const MakeAReservationAvailableTimesForm = ({ restaurant, onClose = () => {} }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [customAvailableTimes, setCustomAvailableTimes] = useState({});
  
  
  useEffect (() => {
    if(selectedDate) {
        const formatedDate = selectedDate.format("MMMM DD, YYYY");
        

        console.log(selectedDate)
    }
}, [selectedDate])
const onSubmit = async () => {
    // Firebase code goes here
    console.log(selectedTimes)
    console.log(selectedDate.format("MMMM DD, YYYY"))
    await makeAvailableTimes(selectedTimes, selectedDate.format("MMMM DD, YYYY") );
    alert ('Reservation Successful!');
    onClose();
}

  return (
    <Content>
      <HeadingSmall style={{ marginBottom: '32px' }}>Choose a Date &amp; Time</HeadingSmall>
      <DatePicker
        id="date-picker"
        date={selectedDate}  
        onDateChange={(newDate) => {
          setSelectedDate(newDate);
        }}
        focused={datePickerFocused}
        onFocusChange={({ focused }) => setDatePickerFocused(focused)}
      />
      <TimeTable>
        <tbody>
          {possibleTimes.map((timeGroup, i) => (
            <tr key={i}>
              {timeGroup.map((time) => (
                
                <TimeOption
                  key={time}
                  selected={selectedTimes.includes(time)}
                  onClick={() => {
                    
                    // Toggle the selection state
                    if (selectedTimes.includes(time)) {
                      setSelectedTimes(selectedTimes.filter((selectedTime) => selectedTime !== time));
                     
                    } else {
                      setSelectedTimes([...selectedTimes, time]);                   
                    }
                  }}
                >
                  {time}
                  
                </TimeOption>
               
              ))}
              
            </tr>
            
          ))}
          
        </tbody>
      </TimeTable>
                  
      <FullWidthButton onClick={onSubmit}>Save the Date</FullWidthButton>
    </Content>
  );
};
